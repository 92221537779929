<!--
 * @Author: CL
 * @Date: 2021-07-31 16:32:19
 * @LastEditTime: 2021-08-01 22:28:31
 * @Description: 游戏展示组件
 * 
 * 1. 传入一个游戏详情信息
-->

<template>
  <div class="game-contain">
    <div class="img-wrap" @click="goGame(info.address)">
      <img v-imglazy="baseURL + info.picture" :alt="info.title">
    </div>
    <div class="text-wrap">
      <div class="title-wrap">
        <h4>{{ info.title }}</h4>
        <div>{{ info.createTime }}</div>
      </div>
      <div class="icon-wrap">
        <div class="label-view">
          <IconFont type="views" />
          <span>{{ info.views }}</span>
          <div class="label-view-wrap">
            <Pop text="浏览次数" />
          </div>
        </div>
        <div class="label-likes">
          <IconFont type="like" />
          <span>{{ info.likes }}</span>
          <div class="label-likes-wrap">
            <Pop text="点赞次数" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconFont from '@/components/IconFont'
import Pop from '@/components/Pop';
import { mapState } from 'vuex';

export default {
  components: {
    IconFont,
    Pop
  },

  props: {
    info: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(['baseURL'])
  },

  methods: {
    goGame(url){
      window.open(url);
    }
  }
}
</script>

<style lang="less" scoped>
.game-contain{
  width: 450px;
  height: 375px;
  box-shadow: 0px 0px 4px 4px #ececec;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 30px;
  cursor: pointer;
  transition: all .8s;

  &:hover{
    transform: scale(0.9);
  }

  .img-wrap{
    width: 100%;
    height: 300px;

    img{
      width: 100%;
      height: 100%;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  .text-wrap{
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    border: 1px solid #e6e6e6;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .title-wrap{
      display: flex;
      align-items: flex-end;

      >div{
        margin-left: 10px;
        font-size: 12px;
      }
    }

    .icon-wrap{
      display: flex;

      .label-view, .label-likes{
        position: relative;
        display: flex;
        align-items: center;
        margin: 0 20px 0 0;

        &.label-view:hover{
          .label-view-wrap{
            opacity: 1;
          }
        }

        &.label-likes:hover{
          .label-likes-wrap{
            opacity: 1;
          }
        }

        .label-view-wrap, .label-likes-wrap{
          opacity: 0;
          transition: opacity 1s;
        }

        >span{
          margin-left: 5px;
        }
      }
    }
  }
}
</style>

