/*
 * @Author: CL
 * @Date: 2021-06-23 21:58:32
 * @LastEditTime: 2021-06-23 22:36:21
 * @Description: 项目信息相关的api
 */

import request from './request';

/**
 * 添加一个项目信息
 */
export function addProject(data = {}) {
  return request({
    url: '/api/project',
    method: 'post',
    data
  })
}

/**
 * 修改项目信息
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export function editProject(id, data = {}) {
  return request({
    url: `/api/project/${id}`,
    method: 'put',
    data
  })
}

/**
 * 删除项目信息
 * @param {*} id 
 * @returns 
 */
export function delProject(id) {
  return request({
    url: `/api/project/${id}`,
    method: 'delete'
  })
}

/**
 * 分页查询项目信息
 * @param {*} page 
 * @param {*} size 
 * @param {*} data 
 * @returns 
 */
export function queryProject(page = 1, size = 10, data = {}) {
  return request({
    url: `/api/project/${page}/${size}`,
    methods: 'get',
    params: data
  })
}