<!--
 * @Author: CL
 * @Date: 2021-07-28 09:08:09
 * @LastEditTime: 2021-08-01 23:38:43
 * @Description: 游戏
-->

<template>
  <div class="games-contain" ref="games" @scroll="handleScroll">
    <div class="game-list">
      <Game v-for="item in gamesList" :key="item.id" :info="item" />
    </div>
    <GoHome />
    <ToTop :isShow="isShow"/>
    <LoadingMore :isShow="isLoadingMore" :bottom="50" />
    <NoMore :isShow="hasMore" word="游戏" />
  </div>
</template>

<script>
import GoHome from '@/components/GoHome';
import Game from './components/game.vue';
import ToTop from '@/components/ToTop';
import { queryProject } from '@/api/project';
import NoMore from '@/components/NoMore';
import LoadingMore from '@/components/LoadingMore';

export default {
  data(){
    return {
      gamesList: [],

      page: 1,
      size: 10,
      total: 0,
      isShow: false,

      hasMore: false,
      isLoadingMore: false
    }
  },

  methods: {
    handleScrollTop(){
      this.$refs.games.scrollTop = 0;
    },

    /**
     * 获取游戏数据
     */
    async getGames(){
      try{
        const { page, size } = this;
        const res = await queryProject(page, size, { isGame: true });
        if(res.code === 200){
          const { count, rows } = res.data;
          this.total = count;
          this.gamesList = rows;
        }
      }catch(err){
        console.log(err);
      }
    },

    /**
     * 加载更多的游戏项目
     */
    async fetchMore(){
      if(this.total == this.gamesList.length){
        this.hasMore = true;
        return;
      };
      this.page++;
      this.isLoadingMore = true;
      try{
        const res = await queryProject(this.page, this.size, { isGame: true });
        await this.$utils.delay(1500);
        this.isLoadingMore = false;
        if(res.code == 200){
          this.gamesList = [...this.gamesList, ...res.data.rows]
        }
      }catch(err){
        console.log(err);
      }
    },

    handleScroll(){
      if(this.$refs.games.scrollTop == 0){
        this.isShow = false;
      }else if(this.$refs.games.scrollTop > 0){
        this.isShow = true;
      }

      if(this.$refs.games.scrollTop + this.$refs.games.clientHeight >= this.$refs.games.scrollHeight - 50){
        this.fetchMore();
      }
    }
  },

  created(){
    this.getGames();
  },

  mounted(){
    this.$bus.$on('scrollTop', this.handleScrollTop);
  },

  destroyed() {
    this.$bus.$off('scrollTop', this.handleScrollTop);
  },

  components: {
    GoHome,
    Game,
    ToTop,
    NoMore,
    LoadingMore
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/mixin.less';

.games-contain{
  .self-fill();
  padding: 20px 0 50px 0;
  overflow: scroll;
  scroll-behavior: smooth;

  .nomore-contain{
    padding-bottom: 30px;
  }
  
  .game-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 950px;
    margin: 0 auto;
  }
}
</style>
