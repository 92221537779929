<!--
 * @Author: CL
 * @Date: 2021-06-25 14:47:48
 * @LastEditTime: 2021-06-25 15:13:10
 * @Description: 简易弹出层组件
-->

<template>
  <div class="pop-contain">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/var.less'; 

.pop-contain{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  font-size: 12px;
  padding: 5px 8px;
  transform: translate(-25%, 100%);
  text-align: center;
  border-radius: 5px;
  background: @success;
  color: #fff;

  &::after{
    position: absolute;
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%) rotateZ(45deg);
    background: @success;
  }
}
</style>